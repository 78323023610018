"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var gsap_1 = require("gsap");
var ScrollTrigger_1 = require("gsap/ScrollTrigger");
var swiper_1 = require("swiper");
var modules_1 = require("swiper/modules");
require("swiper/swiper-bundle.css");
require("@lottiefiles/lottie-player");
require("../scss/style.scss");
/* =================================================== */
// Smooth Scroll
/* =================================================== */
// スムーススクロール
document.querySelectorAll('a[href^="#"]').forEach(function (anchor) {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();
        var href = anchor.getAttribute('href');
        var target = document.getElementById(href.replace('#', ''));
        var targetPosition = target.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({
            top: targetPosition,
            behavior: 'smooth',
        });
    });
});
/* =================================================== */
// ローディング
/* =================================================== */
window.addEventListener('DOMContentLoaded', function () {
    var loader = document.getElementById('loading');
    if (loader) {
        var removeActive = function () {
            loader.classList.remove('is-active');
        };
        setTimeout(removeActive, 1500);
        //ローディングが終わったらナビの非表示を解除
        var nav = document.querySelector('#js-nav');
        nav.classList.remove('is-hidden');
    }
});
/* =================================================== */
// ハンバーガーメニュー
/* =================================================== */
var header = document.getElementById('header');
if (header) {
    var menuBtn_1 = document.querySelector('#js-menu-btn');
    var nav_1 = document.querySelector('#js-nav');
    menuBtn_1.addEventListener('click', function () {
        menuBtn_1.classList.toggle('is-active');
        nav_1.classList.toggle('is-active');
    });
    document.addEventListener('DOMContentLoaded', function () {
        function menuClick() {
            menuBtn_1.classList.remove('is-active');
            nav_1.classList.remove('is-active');
        }
        // 引数に指定したclassの値をもつ要素をすべて取得
        var navLink = document.querySelectorAll('.js-navLink');
        // 上記で取得したすべての要素に対してクリックイベントを適用
        for (var i = 0; i < navLink.length; i++) {
            navLink[i].addEventListener('click', menuClick, false);
        }
    }, false);
}
/* =================================================== */
// 追従フォームリンクの着脱
/* =================================================== */
var formLink = document.getElementById('js-formLink'); //追従ボタンの要素を取得
if (formLink) {
    window.addEventListener('scroll', function () {
        var scrollpoint = window.pageYOffset; //現在のスクロール位置を取得
        var docHeight = document.getElementById('pageMain').clientHeight; //ページ全体の高さを取得
        var dispHeight = window.innerHeight; //表示領域の高さを取得
        var footerHeight = document.getElementById('footer').offsetHeight; //フッターの高さを取得
        if (scrollpoint > docHeight - dispHeight - footerHeight) {
            //追従ボタンを削除するクラスを付与
            formLink.classList.add('is-removed');
        }
        else {
            //追従ボタンを削除するクラスを除去
            formLink.classList.remove('is-removed');
        }
    });
}
/* =================================================== */
// Swiper
/* =================================================== */
swiper_1.default.use([modules_1.Navigation, modules_1.Pagination, modules_1.Autoplay]);
var swiper = new swiper_1.default('.swiper', {
    modules: [modules_1.Navigation, modules_1.Pagination],
    effect: 'fade',
    fadeEffect: {
        crossFade: true, // クロスフェードを有効にする（フェードモードの場合 true 推奨）
    },
    slidesPerView: 1,
    spaceBetween: 45,
    centeredSlides: true,
    loop: true,
    speed: 300,
    autoplay: {
        // 自動再生させる
        delay: 5000,
        disableOnInteraction: false,
        waitForTransition: false, // アニメーションの間も自動再生を止めない（最初のスライドの表示時間を揃えたいときに）
    },
    // If we need pagination
    pagination: {
        el: '.swiper-pagination',
    },
    // Navigation arrows
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
});
/* =================================================== */
// スクロールアニメーション スライドアップフェード
/* =================================================== */
var slideUpElement = document.querySelectorAll('.js-slideUp');
for (var i = 0; i < slideUpElement.length; i++) {
    gsap_1.gsap.set(slideUpElement[i], { opacity: 0, y: 50 }); //初期状態をセット
    gsap_1.gsap.registerPlugin(ScrollTrigger_1.ScrollTrigger);
    ScrollTrigger_1.ScrollTrigger.batch(slideUpElement[i], {
        onEnter: function (batch) {
            return gsap_1.gsap.to(batch, {
                opacity: 1,
                y: 0,
            });
        },
        start: function () { return 'top 90%'; },
        once: true,
        pin: false,
    });
}
/* =================================================== */
// スクロールアニメーション フェードイン
/* =================================================== */
var fadeInElement = document.querySelectorAll('.js-fadeIn');
for (var i = 0; i < fadeInElement.length; i++) {
    gsap_1.gsap.set(fadeInElement[i], { opacity: 0 }); //初期状態をセット
    gsap_1.gsap.registerPlugin(ScrollTrigger_1.ScrollTrigger);
    ScrollTrigger_1.ScrollTrigger.batch(fadeInElement[i], {
        onEnter: function (batch) {
            return gsap_1.gsap.to(batch, {
                opacity: 1,
            });
        },
        start: function () { return 'top 80%'; },
        once: true,
        pin: false,
    });
}
/* =================================================== */
// スクロールアニメーション 画像のブラー
/* =================================================== */
var blurOutElement = document.querySelectorAll('.js-blurOut');
for (var i = 0; i < blurOutElement.length; i++) {
    gsap_1.gsap.set(blurOutElement[i], {
        opacity: 0,
        filter: 'blur(10px)',
    }); //初期状態をセット
    gsap_1.gsap.registerPlugin(ScrollTrigger_1.ScrollTrigger);
    ScrollTrigger_1.ScrollTrigger.batch(blurOutElement[i], {
        onEnter: function (batch) {
            return gsap_1.gsap.to(batch, {
                opacity: 1,
                filter: 'blur(0)',
            });
        },
        start: function () { return 'top 90%'; },
        once: true,
        pin: false,
    });
}
